import React from 'react';
import Welcome from "./welcome";
import Features from "./features";
import Tour from "./tour";
import Testimonials from "./testimonials";

function App() {

  document.title = "Salon Dragon";

  return (
    <div className="inner_container">
      <Welcome />
      <Tour />
      <Features />
      <Testimonials />
    </div>
  );
}

export default App;
