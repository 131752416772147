import "../langs/i18n";

import { Trans, useTranslation } from "react-i18next";

import { Link } from "react-router-dom";
import React from 'react';

function welcome() {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { t, i18n } = useTranslation();

  return (
      <section className="welcome">
        <div className="container">
          <div className="row">
            <div className="col text-center">
              <h1 className="welcome-title">
              <span><Trans>number</Trans></span>
                <span><Trans>callOrText</Trans></span>
              <Trans>welcomeHead</Trans><span><Trans>welcomeHeadBlue</Trans></span></h1>

              <p className="welcome-text"><Trans>welcomeText</Trans></p>

              {/* <a className="lead-button xl inverse shadowed" href="javascript:void(0);" title={t('signUpFree')}>
                <svg height="12px" width="16px">
                  <path d="M16.354,1.353 L6.354,11.354 L6.000,11.000 L5.680,11.384 L0.680,7.384 L1.320,6.616 L5.963,10.330 L15.646,0.646 L16.354,1.353 Z"></path>
                </svg>
                <span><Trans>signUpFree</Trans></span>
              </a> */}

              <Link className="lead-button inverse" to="/pricing" title="Learn More"><Trans>learnMore</Trans></Link>
            </div>
          </div>
        </div>
      </section>
  );
}

export default welcome;
