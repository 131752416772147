import React from "react";

function privacy() {
  return (
    <div className="pt-4 px-5">
      <h1>Privacy Notice</h1>
      <div className="mb-5">
        <p>
          <b font-weight="bold">Effective November 1, 2023</b>
        </p>
        <p>
          SalonDragon’s mission is to help you schedule better. To do so, we
          need personal information from you and the people scheduling time with
          you – there’s no way around it. But we don’t take this responsibility
          lightly. We recognize the importance of privacy and your rights, and
          we want you to feel confident about using our services and in your
          interactions with us. We want you to love SalonDragon as much as we
          do, but if you do not agree with this policy then please do not access
          or use the Website.
        </p>
        <p>
          The sections below contain the information you may want to know about
          this topic. We’ve tried to make them as straightforward as possible,
          but we realize it’s a lot of information. If you still have any
          questions, please reach out to{" "}
          <span>
            <a
              href="mailto:support@salondragon.com"
              rel="noreferrer noopener"
              target="_blank"
            >
              <span>email</span>
            </a>
          </span>
          .
        </p>
      </div>
      <div className="flex">
        <div>
          <hr />
          <h2 className="my-4">Introduction</h2>
          <div overflow="hidden" visibility="hidden">
            <div
              width="100%"
              height="100%"
              className="sc-fc49f4d0-0 sc-8cd2b48-2 jrCcHB kWFnvU"
            >
              <p height="auto" width="100%" className="sc-fc49f4d0-0 kbCXwY">
                This privacy notice (“Privacy Notice” or “Notice”) describes how
                SalonDragon LLC and its affiliate Interview Schedule, Inc. d/b/a
                Prelude (“SalonDragon”, “we”, or “us”) collect, use, and
                disclose your Personal Data. Throughout this document, we will
                use a few definitions to describe various products, roles and
                relevant terms:{" "}
              </p>
              <ul className="pl-4" style={{ listStyleType: "disc" }}>
                <li>
                  <p
                    height="auto"
                    width="100%"
                    className="sc-fc49f4d0-0 kbCXwY"
                  >
                    <b font-weight="bold" className="sc-ed1f50a8-0 gfdHMB">
                      “Website”
                    </b>{" "}
                    means all of the text, data, information, software,
                    graphics, photographs and more that we and our affiliates
                    may make available to you, plus our websites (including the
                    SalonDragon Help Center) and any services, extensions,
                    plug-ins, software, or applications (including SalonDragon
                    Click, SalonDragon for Chrome, SalonDragon for Firefox, and
                    any SalonDragon applications) that we may provide to you
                  </p>
                </li>
                <li>
                  <p
                    height="auto"
                    width="100%"
                    className="sc-fc49f4d0-0 kbCXwY"
                  >
                    <b font-weight="bold" className="sc-ed1f50a8-0 gfdHMB">
                      “Users”{" "}
                    </b>
                    are the people who have created a SalonDragon account
                  </p>
                </li>
                <li>
                  <p
                    height="auto"
                    width="100%"
                    className="sc-fc49f4d0-0 kbCXwY"
                  >
                    “
                    <b font-weight="bold" className="sc-ed1f50a8-0 gfdHMB">
                      Invitees
                    </b>
                    ” are people who have scheduled a meeting with a User but
                    may or may not have registered with SalonDragon. Invitees
                    also include candidates in the context of the Prelude
                    service.{" "}
                  </p>
                </li>
                <li>
                  <p
                    height="auto"
                    width="100%"
                    className="sc-fc49f4d0-0 kbCXwY"
                  >
                    <b font-weight="bold" className="sc-ed1f50a8-0 gfdHMB">
                      “Viewer”{" "}
                    </b>
                    is a person who visits the Website but may or may not be a
                    User or Invitee.
                  </p>
                </li>
                <li>
                  <p
                    height="auto"
                    width="100%"
                    className="sc-fc49f4d0-0 kbCXwY"
                  >
                    “
                    <b font-weight="bold" className="sc-ed1f50a8-0 gfdHMB">
                      Personal Data
                    </b>
                    ” is any data relating to an identified or identifiable
                    natural person that is processed by SalonDragon as described
                    in this Privacy Notice when such information is protected as
                    “personal data” or “personal information” or a similar term
                    under applicable data protection laws.
                  </p>
                </li>
              </ul>
            </div>
          </div>
          <br />
        </div>
        <div>
          <hr />
          <h2 className="my-4">Applicability</h2>
          <div overflow="hidden" visibility="hidden">
            <div
              width="100%"
              height="100%"
              className="sc-fc49f4d0-0 sc-8cd2b48-2 jrCcHB kWFnvU"
            >
              <p height="auto" width="100%" className="sc-fc49f4d0-0 kbCXwY">
                This Privacy Notice applies to Personal Data SalonDragon
                collects when you visit or use our Website as described in the
                “Information We Collect” section of this Privacy Notice below.
                SalonDragon is the controller of that Personal Data. This
                Privacy Notice does not apply to Personal Data within Customer
                Data (as that term is defined in your agreement with
                SalonDragon), such as information a User collects about an
                Invitee when an Invitee books a meeting or interview using our
                services. We process Personal Data within Customer Data on our
                customers’ behalf as a processor or service provider. If you are
                an Invitee and have questions about how your data is processed
                by our customers or wish to exercise your rights with respect to
                that data, you must reach out to the User who collected your
                information. If you contact us about Personal Data within
                Customer Data and are able to identify the company or User with
                whom you have interacted, we will promptly notify the relevant
                customer who collected your data of your inquiry.
              </p>
            </div>
          </div>
          <br />
        </div>
        <div>
          <hr />
          <h2 className="my-4">Information We Collect</h2>
          <div overflow="hidden" visibility="hidden">
            <div
              width="100%"
              height="100%"
              className="sc-fc49f4d0-0 sc-8cd2b48-2 jrCcHB kWFnvU"
            >
              <p height="auto" width="100%" className="sc-fc49f4d0-0 kbCXwY">
                We collect information about you directly from you and
                automatically through your use of our Website. To help you
                protect yourself and your information, we encourage you to
                provide and collect only that information that is necessary for
                using our Website. For example, to schedule a meeting, the only
                information that is necessary is names, email addresses, date,
                and time. Please note that if you choose not to share certain
                Personal Data with us, or refuse certain contact permissions, we
                might not be able to provide certain parts of the Services.
              </p>
              <p height="auto" width="100%" className="sc-fc49f4d0-0 kbCXwY">
                SalonDragon is not directed to children under thirteen (13)
                years of age, and we do not knowingly collect personal Data from
                children under 13. If we discover that a child under 13 has
                provided us with Personal Data, we will promptly delete such
                Personal Data from our systems. For educational service
                providers and schools, please see our{" "}
                <span className="sc-ed1f50a8-0 sc-9f52a7fd-0 MbaJp hCMKnR">
                  <a
                    data-testid="link-color-button"
                    className="sc-4c6b9caf-0 sc-4c6b9caf-1 gSTixA"
                    href="/ferpa"
                  >
                    <span className="sc-5f46498-0 fIparf">
                      FERPA and COPPA Privacy Policy and Notice
                    </span>
                  </a>
                </span>
                .
              </p>
              <p height="auto" width="100%" className="sc-fc49f4d0-0 kbCXwY">
                <b font-weight="bold" className="sc-ed1f50a8-0 gfdHMB">
                  Information You Provide.
                  <br />
                </b>
                SalonDragon collects the following information, either directly
                from Users, Invitees, or Viewers, or through third parties
                regarding Users, Invitees, or Viewers.
              </p>
              <ul className="pl-4" style={{ listStyleType: "disc" }}>
                <li>
                  <p
                    height="auto"
                    width="100%"
                    className="sc-fc49f4d0-0 kbCXwY"
                  >
                    <b font-weight="bold" className="sc-ed1f50a8-0 gfdHMB">
                      Appointment Information.{" "}
                    </b>
                    A User may voluntarily give us certain information when
                    scheduling and setting up appointments. This can include
                    name, email address, phone number; email addresses of other
                    people and/or Invitees; the subject of the meeting; and any
                    other information a User provides or collects upon
                    scheduling, pursuant to the SalonDragon Terms of Use or an
                    executed agreement with SalonDragon. SalonDragon processes
                    the information an Invitee provides to a User when
                    scheduling with the User on the User’s behalf as a processor
                    or service provider.&nbsp;{" "}
                  </p>
                </li>
                <li>
                  <p
                    height="auto"
                    width="100%"
                    className="sc-fc49f4d0-0 kbCXwY"
                  >
                    <b font-weight="bold" className="sc-ed1f50a8-0 gfdHMB">
                      Calendar Information.
                    </b>{" "}
                    A User may connect their calendar with SalonDragon. In most
                    instances, our calendar integration only uses and displays
                    the duration and free/busy status of the events in your
                    calendar so that we don’t book you when you’re busy. A few
                    of our features also use and display the meeting titles in
                    your calendar (these are currently our one-off meeting
                    feature, or if you are booking with another SalonDragon
                    user). In all cases, however,&nbsp; no details about the
                    appointments in your connected calendar are stored in
                    SalonDragon, such as who you are meeting with, their email
                    address, or the meeting title. Although connecting your
                    SalonDragon to our services makes the scheduling process
                    much more efficient, you are not required to connect your
                    calendar to use our services. More information about access
                    the SalonDragon app and extensions have to your connected
                    calendar is available{" "}
                    <span className="sc-ed1f50a8-0 sc-9f52a7fd-0 MbaJp hCMKnR">
                      <a
                        data-testid="link-color-button"
                        href="https://help.SalonDragon.com/hc/en-us/articles/115002939274"
                        rel="noreferrer noopener"
                        target="_blank"
                        className="sc-4c6b9caf-0 sc-4c6b9caf-1 gSTixA"
                      >
                        <span className="sc-5f46498-0 fIparf">here.</span>
                      </a>
                    </span>{" "}
                    SalonDragon processes the calendar information on the User’s
                    behalf as a processor or service provider.&nbsp; (
                    <b font-weight="bold" className="sc-ed1f50a8-0 gfdHMB">
                      For Prelude service Users only
                    </b>
                    , the Prelude service works a bit differently. SalonDragon
                    will have access to the duration, free/busy status of the
                    events in your calendar, attendees, start time, and the
                    title of these events. We need this information in order to
                    understand which events are movable on a User’s calendar so
                    interviews may be scheduled more seamlessly.)&nbsp;&nbsp;
                  </p>
                </li>
                <li>
                  <p
                    height="auto"
                    width="100%"
                    className="sc-fc49f4d0-0 kbCXwY"
                  >
                    <b font-weight="bold" className="sc-ed1f50a8-0 gfdHMB">
                      Information Received from Third Parties
                    </b>
                    . If you choose to connect your account to your account with
                    a third-party service, we may receive or be granted access
                    to information from such third-party service, including
                    Personal Information. For example, if you use the Prelude
                    service and enable applicant tracking systems integrations
                    for the recruiting use case within Prelude, your ATS may
                    send to Prelude certain Personal Information as determined
                    by you.&nbsp; You can stop sharing your information from a
                    third-party service with us by removing our access to that
                    service. SalonDragon processes that information on the
                    User’s behalf as a processor or service provider.
                  </p>
                </li>
                <li>
                  <p
                    height="auto"
                    width="100%"
                    className="sc-fc49f4d0-0 kbCXwY"
                  >
                    <b font-weight="bold" className="sc-ed1f50a8-0 gfdHMB">
                      Account and Billing Information.
                    </b>{" "}
                    Users provide SalonDragon with certain information,
                    including name, email address, username, and password, when
                    you set up your account. If you purchase a premium version
                    of SalonDragon, our third party payment processors will
                    collect and store your billing address and credit card
                    information. We store the last four digits of your credit
                    card number, card type, and the expiration date. (
                    <b font-weight="bold" className="sc-ed1f50a8-0 gfdHMB">
                      For Prelude service Users only,{" "}
                    </b>
                    the payment and billing information will be processed as
                    established in the services agreement between SalonDragon
                    and your organization.){" "}
                  </p>
                </li>
                <li>
                  <p
                    height="auto"
                    width="100%"
                    className="sc-fc49f4d0-0 kbCXwY"
                  >
                    <b font-weight="bold" className="sc-ed1f50a8-0 gfdHMB">
                      Chatbot Data.
                    </b>{" "}
                    If you engage with our virtual chatbot, we will collect the
                    information you provide during the chat, including
                    transcripts of your conversation with the chatbot.
                  </p>
                </li>
                <li>
                  <p
                    height="auto"
                    width="100%"
                    className="sc-fc49f4d0-0 kbCXwY"
                  >
                    <b font-weight="bold" className="sc-ed1f50a8-0 gfdHMB">
                      Marketing Information.{" "}
                    </b>
                    Users, Invitees, or Viewers interested in SalonDragon’s
                    services may contact us through forms made available on the
                    Website and voluntarily give information such as name, work
                    email, phone number, company, and role. Users, Invitees, or
                    Viewers interested in our newsletter may also submit their
                    name and email addresses to join our mailing list. We also
                    receive other similar information provided by you if you
                    participate in an event hosted by SalonDragon or its
                    partners (such as webinars) and in your interactions with
                    our social media accounts.
                  </p>
                </li>
              </ul>
              <p height="auto" width="100%" className="sc-fc49f4d0-0 kbCXwY">
                <b font-weight="bold" className="sc-ed1f50a8-0 gfdHMB">
                  Information Collected Automatically From You.
                </b>
                <br />
                We and our authorized third parties use Cookies, pixels, web
                beacons, and other technologies to receive and store certain
                types of information when you interact with us through your
                computer or mobile device (subject to your consent, opt-out
                preferences or other appropriate legal basis where legally
                required). Using these technologies helps us customize your
                experience with our Website, improve your experience, tailor
                marketing messages, and help us detect and prevent fraud and
                security risks. Here is more specific information about the
                types of information we collect:
              </p>
              <ul className="pl-4" style={{ listStyleType: "disc" }}>
                <li>
                  <p
                    height="auto"
                    width="100%"
                    className="sc-fc49f4d0-0 kbCXwY"
                  >
                    <b font-weight="bold" className="sc-ed1f50a8-0 gfdHMB">
                      Log &amp; Device Data.{" "}
                    </b>
                    When you use SalonDragon, we and our authorized third
                    parties may automatically record certain information (“log
                    data”), including information that your browser sends
                    whenever you visit our Website. This log data may include
                    the web address you came from or are going to, your device
                    model, operating system, browser type, unique device
                    identifier, IP address, mobile network carrier, and time
                    zone or approximate location. Whether we collect some or all
                    of this information often depends on what type of device
                    you’re using and its settings. For example, different types
                    of information are available depending on whether you’re
                    using a Mac or PC, or an iPhone or an Android phone. To
                    learn more about what information your device makes
                    available to us, please check the policies of your device
                    manufacturer or software provider.
                  </p>
                </li>
                <li>
                  <p
                    height="auto"
                    width="100%"
                    className="sc-fc49f4d0-0 kbCXwY"
                  >
                    <b font-weight="bold" className="sc-ed1f50a8-0 gfdHMB">
                      Cookie Data.
                    </b>{" "}
                    Depending on how you’re accessing our services and subject
                    to your consent, opt-out preferences, or other appropriate
                    legal basis where legally required, we and our authorized
                    third parties may use “Cookies” (a small text file sent by
                    your computer each time you visit our Website, unique to
                    your SalonDragon account or your browser) or similar
                    technologies to record log data. When we use Cookies, we may
                    use ‘session’ Cookies (that last until you close your
                    browser) or ‘persistent’ Cookies (that last until you or
                    your browser deletes them). For example, we may use Cookies
                    to keep you logged into SalonDragon. Some of the Cookies we
                    use are associated with your SalonDragon account (including
                    Personal Data about you, such as the email address you gave
                    us), and other Cookies are not. SalonDragon provides a
                    centralized cookie management service across the entire
                    SalonDragon application. You can find the link to manage
                    your cookie preferences at the bottom of the SalonDragon
                    homepage under “Support” or under “Account Settings” within
                    your SalonDragon Website account. (
                    <b font-weight="bold" className="sc-ed1f50a8-0 gfdHMB">
                      For Prelude service Invitees only,
                    </b>{" "}
                    you can opt out through the “Cookie Preferences” link made
                    available to you on the booking page you receive from a
                    Prelude User.)
                  </p>
                </li>
                <li>
                  <p
                    height="auto"
                    width="100%"
                    className="sc-fc49f4d0-0 kbCXwY"
                  >
                    <b font-weight="bold" className="sc-ed1f50a8-0 gfdHMB">
                      Usage Data.
                    </b>{" "}
                    When you use our scheduling platform either as a User or an
                    Invitee, we and our authorized third parties collect certain
                    information about how you use the platform. For example, we
                    collect information on the meeting types our Users use most
                    often, and how many meetings are scheduled each day. We
                    aggregate this information and use it to help us monitor and
                    improve the Services, such as to determine which features
                    are most popular amongst our Users, and which features could
                    be added or improved.
                  </p>
                </li>
                <li>
                  <p
                    height="auto"
                    width="100%"
                    className="sc-fc49f4d0-0 kbCXwY"
                  >
                    <b font-weight="bold" className="sc-ed1f50a8-0 gfdHMB">
                      Third-Party Tools.
                    </b>{" "}
                    We may disclose information to third parties or allow third
                    parties to directly collect information using these
                    technologies on our Website, such as social media companies,
                    advertising networks, companies that provide analytics
                    including ad tracking and reporting, security providers, and
                    others that help us operate our business and Website.&nbsp;
                    We use such third-party tools&nbsp;subject to your consent,
                    opt-out preferences, or other appropriate legal basis where
                    legally required. For example, we use third-party providers
                    such as Google Analytics to provide certain analytics and
                    Viewer interactions services to SalonDragon in connection
                    with our operation of our Website, including the collection
                    and tracking of certain data and information regarding the
                    characteristics and activities of visitors to SalonDragon.
                    You may opt-out of relevant cookies using opt-out features
                    on their respective websites. To learn how Google Analytics
                    collects and processes data, please visit: “How Google uses
                    data when you use our partners’ sites or apps” located at
                    www.google.com/policies/privacy/partners. We also use
                    session replay and similar tools provided by a third party
                    to record your interactions with our Website, such as how
                    you move throughout our Website and engage with our
                    webforms. In addition to analytics, this information helps
                    us improve our Website, our marketing activities, and
                    identify and fix technical issues visitors may be having
                    with our Website. Where available, you may opt-out of
                    relevant cookies&nbsp; and other tracking technologies using
                    opt-out features on their respective websites.
                  </p>
                </li>
              </ul>
              <p height="auto" width="100%" className="sc-fc49f4d0-0 kbCXwY">
                Certain third-party tools may not be opted out of and are
                essential to our service. For example, SalonDragon has
                implemented Google reCAPTCHA Enterprise to help prevent spam and
                abuse. reCAPTCHA Enterprise collects hardware and software
                information, such as device and application data, and sends it
                to Google for purposes of providing, maintaining, and improving
                reCAPTCHA Enterprise and for general security purposes. This
                information is not used by Google for personalized advertising.
                Your use of reCAPTCHA Enterprise is subject to Google’s{" "}
                <span className="sc-ed1f50a8-0 sc-9f52a7fd-0 MbaJp hCMKnR">
                  <a
                    data-testid="link-color-button"
                    href="https://policies.google.com/privacy?hl="
                    rel="noreferrer noopener"
                    target="_blank"
                    className="sc-4c6b9caf-0 sc-4c6b9caf-1 gSTixA"
                  >
                    <span className="sc-5f46498-0 fIparf">Privacy Policy</span>
                  </a>
                </span>{" "}
                and{" "}
                <span className="sc-ed1f50a8-0 sc-9f52a7fd-0 MbaJp hCMKnR">
                  <a
                    data-testid="link-color-button"
                    href="https://policies.google.com/terms?hl="
                    rel="noreferrer noopener"
                    target="_blank"
                    className="sc-4c6b9caf-0 sc-4c6b9caf-1 gSTixA"
                  >
                    <span className="sc-5f46498-0 fIparf">Terms of Use.</span>
                  </a>
                </span>
              </p>
            </div>
          </div>
        </div>
        <div>
          <hr />
          <h2 className="my-4">How We Use Your Information</h2>
          <div overflow="hidden" visibility="hidden">
            <div
              width="100%"
              height="100%"
              className="sc-fc49f4d0-0 sc-8cd2b48-2 jrCcHB kWFnvU"
            >
              <p height="auto" width="100%" className="sc-fc49f4d0-0 kbCXwY">
                We may use information that we collect about you, including
                Personal Data, to:
              </p>
              <ul className="pl-4" style={{ listStyleType: "disc" }}>
                <li>
                  <p
                    height="auto"
                    width="100%"
                    className="sc-fc49f4d0-0 kbCXwY"
                  >
                    <b font-weight="bold" className="sc-ed1f50a8-0 gfdHMB">
                      Provide the SalonDragon Website Service.{" "}
                    </b>
                    We will use your information to provide our Website to you,
                    including to facilitate scheduling; create and manage your
                    account; respond to your inquiries; prevent or address
                    service errors, security, or technical issues; analyze and
                    monitor usage; prevent spam, fraud and abuse on the Website;
                    and for other customer service and support purposes. We use
                    the payment information you provide to us in order to alert
                    you of past, current, and upcoming charges, to allow us to
                    present the billing history to you on your billing page on
                    the Website, and to perform internal financial processes,
                    such as looking at the status of a credit card charge. In
                    the event of a credit card dispute, we also share account
                    information with your bank to verify the legitimacy of a
                    charge.{" "}
                    <b font-weight="bold" className="sc-ed1f50a8-0 gfdHMB">
                      For EU / UK purposes, our legal bases for processing are
                      performance of a contract and legitimate interests.
                    </b>
                  </p>
                </li>
                <li>
                  <p
                    height="auto"
                    width="100%"
                    className="sc-fc49f4d0-0 kbCXwY"
                  >
                    <b font-weight="bold" className="sc-ed1f50a8-0 gfdHMB">
                      Understand and improve our products.{" "}
                    </b>
                    We will perform research and analysis about your use of, or
                    interest in, our products, services, or content, or
                    products, services or content offered by others. We do this
                    to help make our products better and to develop new
                    products.{" "}
                    <b font-weight="bold" className="sc-ed1f50a8-0 gfdHMB">
                      For EU /UK purposes, our legal basis for processing is
                      legitimate interests.
                    </b>
                  </p>
                </li>
                <li>
                  <p
                    height="auto"
                    width="100%"
                    className="sc-fc49f4d0-0 kbCXwY"
                  >
                    <b font-weight="bold" className="sc-ed1f50a8-0 gfdHMB">
                      Communicate with you.
                    </b>
                  </p>
                </li>
              </ul>
              <p height="auto" width="100%" className="sc-fc49f4d0-0 kbCXwY">
                <span
                  text-decoration="underline"
                  className="sc-ed1f50a8-0 imAMjQ"
                >
                  Service-related communications
                </span>
                .
                <b font-weight="bold" className="sc-ed1f50a8-0 gfdHMB">
                  {" "}
                </b>
                We may send you service and administrative emails to ensure the
                service is working properly. We will also email you regarding
                your calendar appointments. These messages are considered part
                of the service and you may not opt out of these messages.{" "}
                <b font-weight="bold" className="sc-ed1f50a8-0 gfdHMB">
                  For EU/UK purposes, our legal basis for processing is
                  performance of a contract.
                </b>
              </p>
              <p height="auto" width="100%" className="sc-fc49f4d0-0 kbCXwY">
                <span
                  text-decoration="underline"
                  className="sc-ed1f50a8-0 imAMjQ"
                >
                  Promotional.
                </span>{" "}
                Subject to your opt-out preference and subscription, we may send
                you emails about new product features or other news about
                SalonDragon or on topics we think would be relevant to you. You
                may opt out of receiving these communications at any time. Visit
                the ‘Your Rights and Choices’ section below. For SalonDragon
                Invitees, please be assured that we do not use the email
                addresses that you enter to schedule a meeting with a
                SalonDragon User to send any type of direct marketing. However,
                you may receive marketing communications if you have used the
                same email address to sign up for them previously or to manage
                your account as a SalonDragon User.{" "}
                <b font-weight="bold" className="sc-ed1f50a8-0 gfdHMB">
                  For EU/UK purposes, our legal basis for processing is consent
                  or legitimate interests.
                </b>{" "}
              </p>
              <p height="auto" width="100%" className="sc-fc49f4d0-0 kbCXwY">
                <span
                  text-decoration="underline"
                  className="sc-ed1f50a8-0 imAMjQ"
                >
                  Responding to your requests.
                </span>{" "}
                We will also use your information to respond to your questions
                or comments.{" "}
                <b font-weight="bold" className="sc-ed1f50a8-0 gfdHMB">
                  For EU/UK purposes, our legal bases for processing are
                  performance of a contract, legitimate interests and/or
                  compliance with a legal obligation.
                </b>{" "}
              </p>
              <p height="auto" width="100%" className="sc-fc49f4d0-0 kbCXwY">
                <span
                  text-decoration="underline"
                  className="sc-ed1f50a8-0 imAMjQ"
                >
                  Administrative.
                </span>{" "}
                We may contact you to inform you about changes in your services,
                our service offering and other important service-related
                notices, such as changes to the Notice or about security or
                fraud notices.{" "}
                <b font-weight="bold" className="sc-ed1f50a8-0 gfdHMB">
                  For EU/UK purposes, our legal bases for processing are
                  performance of a contract, legitimate interests, and/or
                  compliance with a legal obligation.{" "}
                </b>
              </p>
              <ul className="pl-4" style={{ listStyleType: "disc" }}>
                <li>
                  <p
                    height="auto"
                    width="100%"
                    className="sc-fc49f4d0-0 kbCXwY"
                  >
                    <b font-weight="bold" className="sc-ed1f50a8-0 gfdHMB">
                      Protecting Rights and Interests.{" "}
                    </b>
                    We will use your information to protect our rights and
                    interests as well as the rights and interests of our Users
                    and any other person, as well as to enforce this Notice or
                    our Terms of Use
                    <b font-weight="bold" className="sc-ed1f50a8-0 gfdHMB">
                      . For EU/UK purposes, our legal basis for processing is
                      legitimate interests.
                    </b>
                  </p>
                </li>
                <li>
                  <p
                    height="auto"
                    width="100%"
                    className="sc-fc49f4d0-0 kbCXwY"
                  >
                    <b font-weight="bold" className="sc-ed1f50a8-0 gfdHMB">
                      Legal Compliance.
                    </b>{" "}
                    We may use your information to comply with applicable legal
                    or regulatory obligations, including complying with requests
                    from law enforcement or other governmental authorities, or
                    in legal proceedings involving SalonDragon.{" "}
                    <b font-weight="bold" className="sc-ed1f50a8-0 gfdHMB">
                      For EU/UK purposes, our legal basis for processing is
                      compliance with a legal obligation.
                    </b>
                  </p>
                </li>
                <li>
                  <p
                    height="auto"
                    width="100%"
                    className="sc-fc49f4d0-0 kbCXwY"
                  >
                    <b font-weight="bold" className="sc-ed1f50a8-0 gfdHMB">
                      Other.
                    </b>{" "}
                    We also may use your information to manage our business or
                    perform functions as otherwise described to you at the time
                    of collection subject to your consent where legally
                    required.{" "}
                    <b font-weight="bold" className="sc-ed1f50a8-0 gfdHMB">
                      For EU/UK purposes, our legal basis for processing is
                      consent.
                    </b>
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div>
          <hr />
          <h2 className="my-4">With Whom We May Share Your Information</h2>
          <div overflow="hidden" visibility="hidden">
            <div
              width="100%"
              height="100%"
              className="sc-fc49f4d0-0 sc-8cd2b48-2 jrCcHB kWFnvU"
            >
              <p height="auto" width="100%" className="sc-fc49f4d0-0 kbCXwY">
                We may share information we collect about you, including
                Personal Data, in the following ways:
              </p>
              <ul className="pl-4" style={{ listStyleType: "disc" }}>
                <li>
                  <p
                    height="auto"
                    width="100%"
                    className="sc-fc49f4d0-0 kbCXwY"
                  >
                    <b font-weight="bold" className="sc-ed1f50a8-0 gfdHMB">
                      With third-party Service Providers, agents, contractors,
                      or government entities.
                    </b>{" "}
                    We use other companies, agents, or contractors (“Service
                    Providers”) to perform services on our behalf or to assist
                    us with providing services and communicating with you. We
                    may engage Service Providers to process credit card
                    transactions or other payment methods. We may also engage
                    Service Providers to provide services such as monitoring and
                    developing SalonDragon services; aiding in communications,
                    infrastructure, and IT services; customer service; debt
                    collection; analyzing and enhancing data. These Service
                    Providers may have access to your Personal Data or other
                    information to provide these functions. In addition, some of
                    the above-listed types of information that we request may be
                    collected by third-party Service Providers on our behalf.
                    Microsoft is one of SalonDragon’s Service Providers. You may
                    review Microsoft’s Privacy statement{" "}
                    <span className="sc-ed1f50a8-0 sc-9f52a7fd-0 MbaJp hCMKnR">
                      <a
                        data-testid="link-color-button"
                        href="https://privacy.microsoft.com/en-us/privacystatement"
                        rel="noreferrer noopener"
                        target="_blank"
                        className="sc-4c6b9caf-0 sc-4c6b9caf-1 gSTixA"
                      >
                        <span className="sc-5f46498-0 fIparf">here.</span>
                      </a>
                    </span>{" "}
                  </p>
                </li>
              </ul>
              <p height="auto" width="100%" className="sc-fc49f4d0-0 kbCXwY">
                We may share information with Service Providers and government
                entities for legal, security, and safety purposes. This includes
                sharing information to enforce policies or contracts, address
                security breaches, and assist in the investigation of fraud,
                security issues, or other concerns.{" "}
              </p>
              <p height="auto" width="100%" className="sc-fc49f4d0-0 kbCXwY">
                We require Service Providers to agree to take reasonable steps
                to keep the Personal Data that we provide to them secure. We do
                not authorize them to use or disclose your Personal Data except
                in connection with providing their services. You may find the
                list of SalonDragon’s Service Providers who are also platform
                subprocessors{" "}
                <span className="sc-ed1f50a8-0 sc-9f52a7fd-0 MbaJp hCMKnR">
                  <a
                    data-testid="link-color-button"
                    href="https://help.SalonDragon.com/hc/en-us/articles/360047345493-SalonDragon-sub-processors-GDPR-CCPA-"
                    rel="noreferrer noopener"
                    target="_blank"
                    className="sc-4c6b9caf-0 sc-4c6b9caf-1 gSTixA"
                  >
                    <span className="sc-5f46498-0 fIparf">here.</span>
                  </a>
                </span>
              </p>
              <ul className="pl-4" style={{ listStyleType: "disc" }}>
                <li>
                  <p
                    height="auto"
                    width="100%"
                    className="sc-fc49f4d0-0 kbCXwY"
                  >
                    <b font-weight="bold" className="sc-ed1f50a8-0 gfdHMB">
                      Affiliates.
                    </b>{" "}
                    We may disclose information to current or future affiliates
                    or subsidiaries for purposes consistent with this Privacy
                    Notice.
                  </p>
                </li>
                <li>
                  <p
                    height="auto"
                    width="100%"
                    className="sc-fc49f4d0-0 kbCXwY"
                  >
                    <b font-weight="bold" className="sc-ed1f50a8-0 gfdHMB">
                      Government entities.{" "}
                    </b>
                    We may share your data if we believe that disclosure is
                    reasonably necessary to comply with a law, regulation,
                    legal, or governmental request; to respond to a subpoena,
                    court order, warrant, or other legal process; to enforce
                    applicable Terms of Use or this Notice, including
                    investigation of potential violations thereof; to protect
                    the safety, rights, or property of the public, any person,
                    or SalonDragon; to detect, prevent, or otherwise address,
                    security or technical issues, illegal, or suspected illegal
                    activities (including fraud); or as evidence in litigation
                    in which we are involved, as part of a judicial or
                    regulatory proceeding. In this process, SalonDragon is
                    committed to maintaining individuals’ privacy, and all such
                    disclosures are carefully reviewed to ensure their
                    legitimacy, and if disclosure is required, that only the
                    necessary information is provided or that the request is
                    challenged accordingly. Unless prohibited by law,
                    SalonDragon will inform you of a government request it has
                    received.
                  </p>
                </li>
                <li>
                  <p
                    height="auto"
                    width="100%"
                    className="sc-fc49f4d0-0 kbCXwY"
                  >
                    <b font-weight="bold" className="sc-ed1f50a8-0 gfdHMB">
                      Authorized Agents.
                    </b>{" "}
                    If you are using SalonDragon as a paid member of an
                    organization or with your organization’s email domain
                    (thereby representing yourself as a member of the
                    organization), we may share your email address, plan
                    information, and data within your account with an authorized
                    agent of your company upon your company’s request for them
                    to administer the account for the company.
                  </p>
                </li>
                <li>
                  <p
                    height="auto"
                    width="100%"
                    className="sc-fc49f4d0-0 kbCXwY"
                  >
                    <b font-weight="bold" className="sc-ed1f50a8-0 gfdHMB">
                      Reorganization Event.
                    </b>{" "}
                    We may, as a result of a sale, merger, consolidation, change
                    in control, transfer of assets, reorganization, or
                    liquidation of our company (a "Reorganization Event"),
                    transfer or assign your Personal Data to parties involved in
                    the Reorganization Event. You acknowledge that such
                    transfers may occur and are permitted by and subject to this
                    Privacy Notice.
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div>
          <hr />
          <h2 className="my-4">Your Rights and Choices</h2>
          <div overflow="hidden" visibility="hidden">
            <div
              width="100%"
              height="100%"
              className="sc-fc49f4d0-0 sc-8cd2b48-2 jrCcHB kWFnvU"
            >
              <p height="auto" width="100%" className="sc-fc49f4d0-0 kbCXwY">
                Depending on your relationship with SalonDragon, you may
                exercise your rights and choices in the following ways:
              </p>
              <ul className="pl-4" style={{ listStyleType: "disc" }}>
                <li>
                  <p
                    height="auto"
                    width="100%"
                    className="sc-fc49f4d0-0 kbCXwY"
                  >
                    <b font-weight="bold" className="sc-ed1f50a8-0 gfdHMB">
                      E-mail.
                    </b>{" "}
                    As described above, if you do not wish to receive
                    promotional emails from us, you may opt out at any time by
                    following the opt-out link contained in the email itself.
                    Please note that it may take up to ten (10) days to process
                    your request. Please also note that if you opt out of
                    receiving marketing communications from us, we may continue
                    to send to you service-related emails which are not
                    available for opt-out. If you do not wish to receive any
                    service-related emails from us, you have the option to
                    delete your account.
                  </p>
                </li>
                <li>
                  <p
                    height="auto"
                    width="100%"
                    className="sc-fc49f4d0-0 kbCXwY"
                  >
                    <b font-weight="bold" className="sc-ed1f50a8-0 gfdHMB">
                      Cookies.
                    </b>{" "}
                    You may also refrain from providing or may withdraw your
                    consent for Cookies via your browser settings. Your
                    browser’s help function should contain instructions on how
                    to set your computer to accept all Cookies, to notify you
                    when a Cookie is issued, or to not receive Cookies at any
                    time. Please keep in mind that certain Cookies are required
                    to authenticate Users as well as perform some actions within
                    SalonDragon (such as to pay for an event as an Invitee via
                    SalonDragon), so to use the Website, some strictly necessary
                    Cookies are required. You may also manage the use of
                    targeting, performance, and functional cookies on this
                    website by clicking the Cookie Settings link located on the
                    footer of this page.
                  </p>
                </li>
                <li>
                  <p
                    height="auto"
                    width="100%"
                    className="sc-fc49f4d0-0 kbCXwY"
                  >
                    <b font-weight="bold" className="sc-ed1f50a8-0 gfdHMB">
                      Third-party analytics&nbsp;and advertising services.{" "}
                    </b>
                    Some of the Service Providers we use provide the ability to
                    opt-out.
                  </p>
                </li>
                <li>
                  <p
                    height="auto"
                    width="100%"
                    className="sc-fc49f4d0-0 kbCXwY"
                  >
                    <b font-weight="bold" className="sc-ed1f50a8-0 gfdHMB">
                      Google Analytics.
                    </b>{" "}
                    You may opt out of Google Analytics’ services using the
                    Opt-Out feature on their website. The Google Analytics
                    service is provided by Google Inc. You can opt-out from
                    Google Analytics service from using your information by
                    installing the Google Analytics Opt-out Browser tool:{" "}
                    <span className="sc-ed1f50a8-0 sc-9f52a7fd-0 MbaJp hCMKnR">
                      <a
                        data-testid="link-color-button"
                        href="https://tools.google.com/dlpage/gaoptout"
                        rel="noreferrer noopener"
                        target="_blank"
                        className="sc-4c6b9caf-0 sc-4c6b9caf-1 gSTixA"
                      >
                        <span className="sc-5f46498-0 fIparf">
                          https://tools.google.com/dlpage/gaoptout
                        </span>
                      </a>
                    </span>
                    . For more information on the privacy practices of Google,
                    please visit the Google Privacy &amp; Terms web page:{" "}
                    <span className="sc-ed1f50a8-0 sc-9f52a7fd-0 MbaJp hCMKnR">
                      <a
                        data-testid="link-color-button"
                        href="https://policies.google.com/privacy"
                        rel="noreferrer noopener"
                        target="_blank"
                        className="sc-4c6b9caf-0 sc-4c6b9caf-1 gSTixA"
                      >
                        <span className="sc-5f46498-0 fIparf">
                          https://policies.google.com/privacy
                        </span>
                      </a>
                    </span>
                    .
                  </p>
                </li>
                <li>
                  <p
                    height="auto"
                    width="100%"
                    className="sc-fc49f4d0-0 kbCXwY"
                  >
                    <b font-weight="bold" className="sc-ed1f50a8-0 gfdHMB">
                      Clearbit.
                    </b>{" "}
                    You may opt out of Clearbit’s services using their opt-out
                    feature:{" "}
                    <span className="sc-ed1f50a8-0 sc-9f52a7fd-0 MbaJp hCMKnR">
                      <a
                        data-testid="link-color-button"
                        href="https://claim.clearbit.com/claim"
                        rel="noreferrer noopener"
                        target="_blank"
                        className="sc-4c6b9caf-0 sc-4c6b9caf-1 gSTixA"
                      >
                        <span className="sc-5f46498-0 fIparf">
                          https://claim.clearbit.com/claim
                        </span>
                      </a>
                    </span>
                    . For more information on the privacy practices of Clearbit,
                    please visit their privacy policy:{" "}
                    <span className="sc-ed1f50a8-0 sc-9f52a7fd-0 MbaJp hCMKnR">
                      <a
                        data-testid="link-color-button"
                        href="https://clearbit.com/privacy"
                        rel="noreferrer noopener"
                        target="_blank"
                        className="sc-4c6b9caf-0 sc-4c6b9caf-1 gSTixA"
                      >
                        <span className="sc-5f46498-0 fIparf">
                          https://clearbit.com/privacy.
                        </span>
                      </a>
                    </span>
                  </p>
                </li>
                <li>
                  <p
                    height="auto"
                    width="100%"
                    className="sc-fc49f4d0-0 kbCXwY"
                  >
                    <b font-weight="bold" className="sc-ed1f50a8-0 gfdHMB">
                      Facebook Pixel.{" "}
                    </b>
                    You may opt out of Facebook Pixel’s services using their
                    opt-out feature:{" "}
                    <span className="sc-ed1f50a8-0 sc-9f52a7fd-0 MbaJp hCMKnR">
                      <a
                        data-testid="link-color-button"
                        href="https://www.facebook.com/help/568137493302217"
                        rel="noreferrer noopener"
                        target="_blank"
                        className="sc-4c6b9caf-0 sc-4c6b9caf-1 gSTixA"
                      >
                        <span className="sc-5f46498-0 fIparf">
                          https://facebook.com/help/568137493302217
                        </span>
                      </a>
                    </span>
                    . For more information on the privacy practices of Facebook,
                    please visit their Data Policy:{" "}
                    <span className="sc-ed1f50a8-0 sc-9f52a7fd-0 MbaJp hCMKnR">
                      <a
                        data-testid="link-color-button"
                        href="https://www.facebook.com/privacy/policy/?entry_point=data_policy_redirect&amp;entry=0"
                        rel="noreferrer noopener"
                        target="_blank"
                        className="sc-4c6b9caf-0 sc-4c6b9caf-1 gSTixA"
                      >
                        <span className="sc-5f46498-0 fIparf">
                          https://facebook.com/about/privacy
                        </span>
                      </a>
                    </span>
                  </p>
                </li>
                <li>
                  <p
                    height="auto"
                    width="100%"
                    className="sc-fc49f4d0-0 kbCXwY"
                  >
                    <b font-weight="bold" className="sc-ed1f50a8-0 gfdHMB">
                      MNTN.{" "}
                    </b>
                    You may opt out of TV advertising using their opt-out
                    feature:{" "}
                    <span className="sc-ed1f50a8-0 sc-9f52a7fd-0 MbaJp hCMKnR">
                      <a
                        data-testid="link-color-button"
                        href="https://mountain.com/opt-out/"
                        rel="noreferrer noopener"
                        target="_blank"
                        className="sc-4c6b9caf-0 sc-4c6b9caf-1 gSTixA"
                      >
                        <span className="sc-5f46498-0 fIparf">
                          <span
                            text-decoration="underline"
                            className="sc-ed1f50a8-0 imAMjQ"
                          >
                            https://mountain.com/opt-out/
                          </span>
                        </span>
                      </a>
                    </span>
                    . For more information on the privacy practices of MNTN,
                    please visit their privacy policy:{" "}
                    <span className="sc-ed1f50a8-0 sc-9f52a7fd-0 MbaJp hCMKnR">
                      <a
                        data-testid="link-color-button"
                        href="https://mountain.com/privacy-policy/"
                        rel="noreferrer noopener"
                        target="_blank"
                        className="sc-4c6b9caf-0 sc-4c6b9caf-1 gSTixA"
                      >
                        <span className="sc-5f46498-0 fIparf">
                          <span
                            text-decoration="underline"
                            className="sc-ed1f50a8-0 imAMjQ"
                          >
                            https://mountain.com/privacy-policy/
                          </span>
                        </span>
                      </a>
                    </span>
                    .{" "}
                  </p>
                </li>
              </ul>
              <p height="auto" width="100%" className="sc-fc49f4d0-0 kbCXwY">
                <b font-weight="bold" className="sc-ed1f50a8-0 gfdHMB">
                  Additional Rights.
                </b>{" "}
                Depending on where you live, you may have the following rights,
                subject to any applicable exemptions or limitations:
              </p>
              <ul className="pl-4" style={{ listStyleType: "disc" }}>
                <li>
                  <p
                    height="auto"
                    width="100%"
                    className="sc-fc49f4d0-0 kbCXwY"
                  >
                    The right to know and access your Personal Data, such as the
                    categories of Personal Data we have collected, the sources
                    of Personal Data, the purposes of collection, and how we
                    used, disclosed, sold, or shared Personal Data;
                  </p>
                </li>
                <li>
                  <p
                    height="auto"
                    width="100%"
                    className="sc-fc49f4d0-0 kbCXwY"
                  >
                    The right to correct inaccurate Personal Data that we
                    maintain about you;
                  </p>
                </li>
                <li>
                  <p
                    height="auto"
                    width="100%"
                    className="sc-fc49f4d0-0 kbCXwY"
                  >
                    The right to delete your Personal Data under specific
                    circumstances;
                  </p>
                </li>
                <li>
                  <p
                    height="auto"
                    width="100%"
                    className="sc-fc49f4d0-0 kbCXwY"
                  >
                    The right to opt out of the sale or sharing of your Personal
                    Data, as such terms are defined by applicable laws;
                  </p>
                </li>
                <li>
                  <p
                    height="auto"
                    width="100%"
                    className="sc-fc49f4d0-0 kbCXwY"
                  >
                    The right to object or opt out of certain types of
                    processing, such as targeted advertising, direct marketing,
                    and certain types of profiling and automated
                    decision-making;
                  </p>
                </li>
                <li>
                  <p
                    height="auto"
                    width="100%"
                    className="sc-fc49f4d0-0 kbCXwY"
                  >
                    The right to request the restriction of processing of your
                    Personal Data; <br />
                    The right to data portability, which means requesting a copy
                    of your Personal Data in an accessible format;
                  </p>
                </li>
                <li>
                  <p
                    height="auto"
                    width="100%"
                    className="sc-fc49f4d0-0 kbCXwY"
                  >
                    The right to withdraw your consent under certain
                    circumstances; and
                  </p>
                </li>
                <li>
                  <p
                    height="auto"
                    width="100%"
                    className="sc-fc49f4d0-0 kbCXwY"
                  >
                    The right to lodge a complaint with the relevant data
                    protection supervisory authority. Where applicable, you can
                    find contact information for your data protection
                    supervisory authority on the European Data Protection
                    Board’s website,{" "}
                    <span className="sc-ed1f50a8-0 sc-9f52a7fd-0 MbaJp hCMKnR">
                      <a
                        data-testid="link-color-button"
                        href="https://edpb.europa.eu/about-edpb/about-edpb/members_en"
                        rel="noreferrer noopener"
                        target="_blank"
                        className="sc-4c6b9caf-0 sc-4c6b9caf-1 gSTixA"
                      >
                        <span className="sc-5f46498-0 fIparf">
                          https://edpb.europa.eu/about-edpb/about-edpb/members_en
                        </span>
                      </a>
                    </span>
                    , or through other publicly available sources.{" "}
                  </p>
                </li>
              </ul>
              <p height="auto" width="100%" className="sc-fc49f4d0-0 kbCXwY">
                If you are located in California and access the Website, our use
                of certain cookies could be considered a “sale” or “share” under
                the CCPA/CPRA. If you are a California, Virginia, Colorado, or
                Connecticut resident, you may opt out of use of cookies and
                other technologies for targeted advertising purposes by
                navigating to the “Your Privacy Choices” or “Cookie Settings”
                link in the footer of the Website or clicking through the option
                in the banner that appears when you first visit our website to
                opt out of targeting cookies. In addition to opting out with
                SalonDragon, there are other mechanisms generally available by
                advertising groups for consumers to opt out of interest-based
                advertising from a large number of advertising providers at
                once, including but not limited to, using the following links:{" "}
                <span className="sc-ed1f50a8-0 sc-9f52a7fd-0 MbaJp hCMKnR">
                  <a
                    data-testid="link-color-button"
                    href="https://optout.networkadvertising.org/"
                    rel="noreferrer noopener"
                    target="_blank"
                    className="sc-4c6b9caf-0 sc-4c6b9caf-1 gSTixA"
                  >
                    <span className="sc-5f46498-0 fIparf">
                      <span
                        text-decoration="underline"
                        className="sc-ed1f50a8-0 imAMjQ"
                      >
                        https://optout.networkadvertising.org/
                      </span>
                    </span>
                  </a>
                </span>{" "}
                and{" "}
                <span className="sc-ed1f50a8-0 sc-9f52a7fd-0 MbaJp hCMKnR">
                  <a
                    data-testid="link-color-button"
                    href="https://optout.aboutads.info/?c=2&amp;lang=EN"
                    rel="noreferrer noopener"
                    target="_blank"
                    className="sc-4c6b9caf-0 sc-4c6b9caf-1 gSTixA"
                  >
                    <span className="sc-5f46498-0 fIparf">
                      <span
                        text-decoration="underline"
                        className="sc-ed1f50a8-0 imAMjQ"
                      >
                        https://optout.aboutads.info/?c=2&amp;lang=EN
                      </span>
                    </span>
                  </a>
                </span>
                . Please note that these mechanisms are not managed by
                SalonDragon and SalonDragon is not an advertising provider.
              </p>
              <p height="auto" width="100%" className="sc-fc49f4d0-0 kbCXwY">
                To the extent any of the above rights are applicable, you may
                exercise your rights by contacting us via{" "}
                <span className="sc-ed1f50a8-0 sc-9f52a7fd-0 MbaJp hCMKnR">
                  <a
                    data-testid="link-color-button"
                    href="mailto:support@salondragon.com"
                    rel="noreferrer noopener"
                    target="_blank"
                    className="sc-4c6b9caf-0 sc-4c6b9caf-1 gSTixA"
                  >
                    <span className="sc-5f46498-0 fIparf">email</span>
                  </a>
                </span>
                . We will take steps to verify your identity before processing
                certain requests. We will not fulfill your request unless you
                have provided sufficient information for us to reasonably verify
                you are the individual about whom we collected Personal Data. If
                you have an Account with us, we will use our existing Account
                authentication practices to verify your identity. If you do not
                have an Account with us, we may request additional information
                about you to verify your identity. We will only use the Personal
                Data provided in the verification process to verify your
                identity or authority to make a request and to track and
                document request responses, unless you initially provided the
                information for another purpose.
              </p>
              <p height="auto" width="100%" className="sc-fc49f4d0-0 kbCXwY">
                You may be able to use an authorized agent to submit a rights
                request on your behalf. When we verify your agent’s request, we
                may verify both your and your agent’s identity and request a
                signed document from you that authorizes your agent to make the
                request on your behalf. To protect your Personal Data, we
                reserve the right to deny a request from an agent that does not
                submit proof that they have been authorized by you to act on
                their behalf.
              </p>
              <p height="auto" width="100%" className="sc-fc49f4d0-0 kbCXwY">
                Certain laws may give you a right to appeal any denials of your
                request to exercise your rights. If we deny your request and you
                would like to submit an appeal, please contact us via{" "}
                <span className="sc-ed1f50a8-0 sc-9f52a7fd-0 MbaJp hCMKnR">
                  <a
                    data-testid="link-color-button"
                    href="mailto:support@salondragon.com"
                    rel="noreferrer noopener"
                    target="_blank"
                    className="sc-4c6b9caf-0 sc-4c6b9caf-1 gSTixA"
                  >
                    <span className="sc-5f46498-0 fIparf">email</span>
                  </a>
                </span>
                .
              </p>
              <p height="auto" width="100%" className="sc-fc49f4d0-0 kbCXwY">
                <b font-weight="bold" className="sc-ed1f50a8-0 gfdHMB">
                  Note on Third-Party Links.
                </b>
                <br />
                Our Website may contain links to third-party websites and
                applications. Subject to your opt-out or consent preferences, we
                may also use third-party advertisers, ad networks, and other
                advertising, marketing, and promotional companies, to serve
                advertisements on our Website. Any access to and use of such
                linked websites and applications is not governed by this Notice
                but instead is governed by the privacy policies of those third
                parties. We do not endorse these parties, their content, or any
                products and services they offer, and we are not responsible for
                the information practices of such third-party websites or
                applications.
              </p>
            </div>
          </div>
        </div>
        <div>
          <hr />
          <h2 className="my-4">Security and Storage of Information</h2>
          <div overflow="hidden" visibility="hidden">
            <div
              width="100%"
              height="100%"
              className="sc-fc49f4d0-0 sc-8cd2b48-2 jrCcHB kWFnvU"
            >
              <p height="auto" width="100%" className="sc-fc49f4d0-0 kbCXwY">
                Given the nature of communications and information processing
                technology, there is no guarantee that Personal Data will be
                absolutely safe from access, alteration, or destruction by a
                breach of any of our physical, technical, and managerial
                safeguards. However, SalonDragon takes the security of your
                Personal Data very seriously. We work hard to protect the
                Personal Data that you provide from loss, misuse, unauthorized
                access, or disclosure and we have taken reasonable steps to help
                protect the Personal Data we collect. We have obtained industry
                recognized certifications and audits such as the ISO/IEC 27001,
                which affirm our commitment to our security program
                (certification not applicable to the Prelude service). More
                information on SalonDragon security and storage practices is
                available{" "}
                <span className="sc-ed1f50a8-0 sc-9f52a7fd-0 MbaJp hCMKnR">
                  <a
                    data-testid="link-color-button"
                    className="sc-4c6b9caf-0 sc-4c6b9caf-1 gSTixA"
                    href="/security"
                  >
                    <span className="sc-5f46498-0 fIparf">here.</span>
                  </a>
                </span>
              </p>
              <p height="auto" width="100%" className="sc-fc49f4d0-0 kbCXwY">
                You should also take steps to protect against unauthorized
                access to your device and account by, among other things,
                choosing a unique and complex password that nobody else knows or
                can easily guess and keeping your log-in and password private.
                We are not responsible for any lost, stolen, or compromised
                passwords or for any activity on your account via unauthorized
                password activity.
              </p>
              <p height="auto" width="100%" className="sc-fc49f4d0-0 kbCXwY">
                We retain the Personal Data we collect for so long as is
                reasonably necessary to fulfill the purposes for which the data
                was collected, to perform our contractual and legal obligations
                (including any exemptions or exceptions contemplated by law),
                and for any applicable statute of limitations periods for the
                purposes of bringing and defending claims.
              </p>
            </div>
          </div>
        </div>
        <div>
          <hr />
          <h2 className="my-4">Users Outside the USA</h2>
          <div overflow="hidden" visibility="hidden">
            <div
              width="100%"
              height="100%"
              className="sc-fc49f4d0-0 sc-8cd2b48-2 jrCcHB kWFnvU"
            >
              <p height="auto" width="100%" className="sc-fc49f4d0-0 kbCXwY">
                Our application and database servers are located here in the
                United States.
              </p>
              <p height="auto" width="100%" className="sc-fc49f4d0-0 kbCXwY">
                If you are an individual located in the European Economic Area,
                the United Kingdom, Canada or another jurisdiction outside of
                the United States with laws and regulations governing Personal
                Data collection, use, and disclosure that differ from United
                States laws, please be aware that information we collect
                (including through the use of methods such as Cookies and other
                web technologies) will be processed and stored in the United
                States or in other countries where we or our third-party Service
                Providers have operations. By submitting your Personal Data to
                SalonDragon and using SalonDragon, you expressly consent to have
                your Personal Data transferred to, processed, and stored in the
                United States or another jurisdiction which may not offer the
                same level of privacy protection as those in the country where
                you reside or are a citizen. (For a list of applicable
                jurisdictions, you may check our{" "}
                <span className="sc-ed1f50a8-0 sc-9f52a7fd-0 MbaJp hCMKnR">
                  <a
                    data-testid="link-color-button"
                    href="https://help.SalonDragon.com/hc/en-us/articles/360047345493-SalonDragon-sub-processors-GDPR-CCPA-"
                    rel="noreferrer noopener"
                    target="_blank"
                    className="sc-4c6b9caf-0 sc-4c6b9caf-1 gSTixA"
                  >
                    <span className="sc-5f46498-0 fIparf">
                      subprocessors list.
                    </span>
                  </a>
                </span>
                )
              </p>
              <p height="auto" width="100%" className="sc-fc49f4d0-0 kbCXwY">
                In connection with the operation of its Website, SalonDragon may
                transfer your Personal Data to various locations, which may
                include locations both inside and outside of the European
                Economic Area. We rely on Standard Contractual Clauses and the
                UK Addendum to legally transfer Personal Data submitted relating
                to individuals in the European Economic Area, the United Kingdom
                and Switzerland.
              </p>
              <p height="auto" width="100%" className="sc-fc49f4d0-0 kbCXwY">
                SalonDragon has designated representatives in the European
                Economic Area and in the United Kingdom in accordance with the
                applicable requirements in the GDPR and UK GDPR. If you are
                located in these jurisdictions, you can contact our
                representatives at any time with any questions you may have
                about data protection. Their contact details are found in the
                “Contacting Us” section below.&nbsp;
              </p>
            </div>
          </div>
        </div>
        <div>
          <hr />
          <h2 className="my-4">EU-U.S. Data Privacy Framework Notice</h2>
          <div overflow="hidden" visibility="hidden">
            <div
              width="100%"
              height="100%"
              className="sc-fc49f4d0-0 sc-8cd2b48-2 jrCcHB kWFnvU"
            >
              <p height="auto" width="100%" className="sc-fc49f4d0-0 kbCXwY">
                SalonDragon, LLC and its affiliate Interview Schedule, Inc.
                d/b/a Prelude (collectively referred to in this section as
                “SalonDragon” or “we”) comply with the EU-U.S. Data Privacy
                Framework (EU-U.S. DPF), the UK Extension to the EU-U.S. DPF,
                and the Swiss-U.S. Data Privacy Framework (Swiss-U.S. DPF) as
                set forth by the U.S. Department of Commerce. SalonDragon has
                certified to the U.S. Department of Commerce that it adheres to
                the EU-U.S. Data Privacy Framework Principles (EU-U.S. DPF
                Principles) with regard to the processing of personal data
                received from the European Union in reliance on the EU-U.S. DPF
                and from the United Kingdom (and Gibraltar) in reliance on the
                UK Extension to the EU-U.S. DPF.&nbsp; SalonDragon has certified
                to the U.S. Department of Commerce that it adheres to the
                Swiss-U.S. Data Privacy Framework Principles (Swiss-U.S. DPF
                Principles) with regard to the processing of personal data
                received from Switzerland in reliance on the Swiss-U.S.
                DPF.&nbsp; If there is any conflict between the terms in this
                privacy policy and the EU-U.S. DPF Principles and/or the
                Swiss-U.S. DPF Principles, the Principles shall govern.&nbsp; To
                learn more about the Data Privacy Framework (DPF) program, and
                to view our certification, please visit{" "}
                <span className="sc-ed1f50a8-0 sc-9f52a7fd-0 MbaJp hCMKnR">
                  <a
                    data-testid="link-color-button"
                    href="https://www.dataprivacyframework.gov/"
                    rel="noreferrer noopener"
                    target="_blank"
                    className="sc-4c6b9caf-0 sc-4c6b9caf-1 gSTixA"
                  >
                    <span className="sc-5f46498-0 fIparf">
                      https://www.dataprivacyframework.gov/
                    </span>
                  </a>
                </span>
                .
              </p>
              <p height="auto" width="100%" className="sc-fc49f4d0-0 kbCXwY">
                In compliance with the DPF principles, when we transfer Personal
                Data to a third party acting as our agent, we will be liable
                under the Principles if our agent processes such Personal Data
                in a manner inconsistent with the Principles unless we prove we
                are not responsible for the event giving rise to the damage. We
                may be required to disclose your Personal Data in response to a
                lawful request made by public authorities, including to meet
                national security or law enforcement requirements.
              </p>
              <p height="auto" width="100%" className="sc-fc49f4d0-0 kbCXwY">
                The Federal Trade Commission has jurisdiction over SalonDragon’s
                compliance with the EU-U.S. Data Privacy Framework (EU-U.S.
                DPF), the UK Extension to the EU-U.S. DPF, and the Swiss-U.S.
                Data Privacy Framework (Swiss-U.S. DPF).
              </p>
              <p height="auto" width="100%" className="sc-fc49f4d0-0 kbCXwY">
                In compliance with the EU-U.S. DPF,&nbsp; the UK Extension to
                the EU-U.S. DPF, and the Swiss-U.S. DPF, SalonDragon commits to
                resolve DPF Principles-related complaints about our collection
                and use of your personal information.&nbsp; EU, UK, and Swiss
                individuals with inquiries or complaints regarding our handling
                of Personal Data received in reliance on the EU-U.S. DPF, the UK
                Extension to the EU-U.S. DPF, and the Swiss-U.S. DPF should
                first contact SalonDragon at the contact methods set out in the
                “Contacting Us” section at the end of this Privacy Notice. Our
                goal is to address your complaint and make it right. However, if
                we can’t resolve your complaint,&nbsp; you may contact JAMS, our
                U.S.-based third-party dispute resolution provider at
                https://www.jamsadr.com/file-a-dpf-claim (free of charge). You
                may also contact your local data protection authority within the
                European Economic Area or Switzerland (as applicable) for
                unresolved complaints.&nbsp; It is also possible, under certain
                conditions, to invoke binding arbitration for complaints
                regarding DPF compliance not resolved by any of the other DPF
                mechanisms. Please see the{" "}
                <span className="sc-ed1f50a8-0 sc-9f52a7fd-0 MbaJp hCMKnR">
                  <a
                    data-testid="link-color-button"
                    href="https://www.dataprivacyframework.gov/s/article/ANNEX-I-introduction-dpf?tabset-35584=2"
                    rel="noreferrer noopener"
                    target="_blank"
                    className="sc-4c6b9caf-0 sc-4c6b9caf-1 gSTixA"
                  >
                    <span className="sc-5f46498-0 fIparf">
                      <span
                        text-decoration="underline"
                        className="sc-ed1f50a8-0 imAMjQ"
                      >
                        Data Privacy Framework website
                      </span>
                    </span>
                  </a>
                </span>{" "}
                for more information on this option.
              </p>
            </div>
          </div>
        </div>
        <div>
          <hr />
          <h2 className="my-4">
            Additional California Resident Privacy Disclosures
          </h2>
          <div overflow="hidden" visibility="hidden">
            <div
              width="100%"
              height="100%"
              className="sc-fc49f4d0-0 sc-8cd2b48-2 jrCcHB kWFnvU"
            >
              <p height="auto" width="100%" className="sc-fc49f4d0-0 kbCXwY">
                Under the California Consumer Privacy Act of 2018 and any
                subsequent amendments including the California Privacy Rights
                Act of 2020 (collectively, “CCPA”), California residents are
                entitled to the following additional disclosures about our data
                processing. These disclosures apply solely to Users, Viewers,
                and Invitees who live in the State of California (“California
                Residents”). All terms used in this section have the same
                meaning as when used in the CCPA. California Residents may also
                review our Notice at Collection for our Website{" "}
                <span className="sc-ed1f50a8-0 sc-9f52a7fd-0 MbaJp hCMKnR">
                  <a
                    data-testid="link-color-button"
                    className="sc-4c6b9caf-0 sc-4c6b9caf-1 gSTixA"
                    href="/ccpa"
                  >
                    <span className="sc-5f46498-0 fIparf">here</span>
                  </a>
                </span>
                .
              </p>
              <ul className="pl-4" style={{ listStyleType: "disc" }}>
                <li>
                  <p
                    height="auto"
                    width="100%"
                    className="sc-fc49f4d0-0 kbCXwY"
                  >
                    In the preceding 12 months, we have collected the categories
                    of Personal Data: identifiers, personal information
                    categories listed in the California Customer Records statute
                    (Cal. Civ. Code § 1798.80(e)); commercial information; and
                    internet or other similar network activity. The purposes for
                    which we have collected Personal Data and the sources of
                    that information are described above in the Information we
                    Collect and the How We Use Your Information sections above.
                  </p>
                </li>
                <li>
                  <p
                    height="auto"
                    width="100%"
                    className="sc-fc49f4d0-0 kbCXwY"
                  >
                    In the preceding 12 months, we have disclosed Personal Data
                    for a business purpose as detailed in the With Whom We May
                    Share Your Information section above.
                  </p>
                </li>
                <li>
                  <p
                    height="auto"
                    width="100%"
                    className="sc-fc49f4d0-0 kbCXwY"
                  >
                    We may also sell or share information to the extent our use
                    of cookies and tracking technologies for targeted
                    advertising constitutes a “sale” under the CCPA/CPRA. Your
                    opt-out rights are described in the Your Rights and Choices
                    section above. In the preceding 12 months, we have sold or
                    shared the following categories of Personal Data with our
                    targeted advertising service providers and partners:
                    identifiers and internet or other similar network activity.
                  </p>
                </li>
                <li>
                  <p
                    height="auto"
                    width="100%"
                    className="sc-fc49f4d0-0 kbCXwY"
                  >
                    We do not knowingly sell the Personal Data of minors under
                    16.
                  </p>
                </li>
              </ul>
              <p height="auto" width="100%" className="sc-fc49f4d0-0 kbCXwY">
                <b font-weight="bold" className="sc-ed1f50a8-0 gfdHMB">
                  Response to Requests.
                </b>
                <br />
                For an explanation of the rights you may have as a California
                resident, please see the Your Rights and Choices section above.
                We will attempt to respond to California Resident requests in as
                timely a fashion as possible. In the event of delays over 45
                days, we will inform you of the reason and extension period in
                writing. If you have an account with us, we may deliver our
                written response to that account. Any disclosures we provide
                will only cover the 12-month period preceding the verifiable
                receipt of a California Resident’s request. The response we
                provide will explain the reasons we cannot comply with a
                request, if applicable. We do not charge a fee to process or
                respond to your verifiable consumer request unless it is
                excessive, repetitive, or manifestly unfounded. If we determine
                that the request warrants a fee, we will tell you why we made
                that decision and provide you with a cost estimate before
                fulfilling your request.
              </p>
              <p height="auto" width="100%" className="sc-fc49f4d0-0 kbCXwY">
                <b font-weight="bold" className="sc-ed1f50a8-0 gfdHMB">
                  Shine the Light.
                </b>
                <br />
                This Privacy Notice describes how we may share your information,
                including for marketing purposes. California residents are
                entitled to request and obtain from us once per calendar year
                information about any of your Personal Data shared with third
                parties for their own direct marketing purposes, including the
                categories of information and the names and addresses of those
                businesses with which we have shared such information. To
                request this information and for any other questions about our
                privacy practices and compliance with California law, please
                contact us via{" "}
                <span className="sc-ed1f50a8-0 sc-9f52a7fd-0 MbaJp hCMKnR">
                  <a
                    data-testid="link-color-button"
                    href="mailto:support@salondragon.com"
                    rel="noreferrer noopener"
                    target="_blank"
                    className="sc-4c6b9caf-0 sc-4c6b9caf-1 gSTixA"
                  >
                    <span className="sc-5f46498-0 fIparf">email.</span>
                  </a>
                </span>
              </p>
            </div>
          </div>
        </div>
        <div>
          <hr />
          <h2 className="my-4">Changes to this Notice</h2>
          <div overflow="hidden" visibility="hidden">
            <div
              width="100%"
              height="100%"
              className="sc-fc49f4d0-0 sc-8cd2b48-2 jrCcHB kWFnvU"
            >
              <p height="auto" width="100%" className="sc-fc49f4d0-0 kbCXwY">
                This Notice is current as of the Effective Date set forth above.
                This Notice may change if there is a material change to the way
                information is handled at SalonDragon, or to clarify our Notice
                or adjust clerical errors. If any changes are made, we’ll post
                them on this page, so please be sure to check back periodically.
                If you continue to use SalonDragon after those changes are in
                effect, you agree to the revised Notice.
              </p>
              <p height="auto" width="100%" className="sc-fc49f4d0-0 kbCXwY">
                This notice was updated on:
              </p>
              <ul className="pl-4" style={{ listStyleType: "disc" }}>
                <li>
                  <p
                    height="auto"
                    width="100%"
                    className="sc-fc49f4d0-0 kbCXwY"
                  >
                    January 2023 – to improve flow, include required information
                    to comply with newer requirements (e.g., CPRA), add
                    clarification on scope, correct mailing and email addresses
                    and expand on GDPR-specific information (e.g., clarity on
                    the legal bases SalonDragon uses to process information).
                  </p>
                </li>
                <li>
                  <p
                    height="auto"
                    width="100%"
                    className="sc-fc49f4d0-0 kbCXwY"
                  >
                    March 2023 – to include EU and UK representative contact
                    information.&nbsp;
                  </p>
                </li>
                <li>
                  <p
                    height="auto"
                    width="100%"
                    className="sc-fc49f4d0-0 kbCXwY"
                  >
                    May 2023 - to clarify terms, integrate processing details
                    around the Prelude service in the notice, add reference to
                    the ISO/IEC 27001 certification, and update SalonDragon’s
                    entity information.
                  </p>
                </li>
                <li>
                  <p
                    height="auto"
                    width="100%"
                    className="sc-fc49f4d0-0 kbCXwY"
                  >
                    June 2023 - to add information about opting out of MNTN, add
                    links to advertising organizations that allow consumers to
                    opt out of targeted advertising, and adding reference to
                    Colorado and Connecticut privacy laws
                  </p>
                </li>
                <li>
                  <p
                    height="auto"
                    width="100%"
                    className="sc-fc49f4d0-0 kbCXwY"
                  >
                    November 2023 - updates to comply with SalonDragon’s
                    certification to the EU-U.S., UK, and Swiss Data Privacy
                    Framework
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div>
          <hr />
          <h2 className="my-4">Contacting Us</h2>
          <div overflow="hidden" visibility="hidden">
            <div
              width="100%"
              height="100%"
              className="sc-fc49f4d0-0 sc-8cd2b48-2 jrCcHB kWFnvU"
            >
              <p height="auto" width="100%" className="sc-fc49f4d0-0 kbCXwY">
                If you have any questions or comments about this Notice or
                anything related to privacy, please contact us via{" "}
                <span className="sc-ed1f50a8-0 sc-9f52a7fd-0 MbaJp hCMKnR">
                  <a
                    data-testid="link-color-button"
                    href="mailto:support@salondragon.com"
                    rel="noreferrer noopener"
                    target="_blank"
                    className="sc-4c6b9caf-0 sc-4c6b9caf-1 gSTixA"
                  >
                    <span className="sc-5f46498-0 fIparf">email</span>
                  </a>
                </span>
                .
              </p>
            </div>
          </div>
        </div>
      </div>
      <hr />
    </div>
  );
}

export default privacy;
