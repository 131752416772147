import React, { useEffect } from 'react';
import { Link } from "react-router-dom";
import "../langs/i18n";
import { useTranslation, Trans } from "react-i18next";
import $ from 'jquery';

import notifs from './notifs.json';

import logo from '../images/salonDragon.png';
import viet from '../images/flags/vietnam.svg';
import usa from '../images/flags/united-states-of-america.svg';

function header() {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { t, i18n } = useTranslation();
  let p = "";

  const changeLanguage = lng => {
    i18n.changeLanguage(lng);
    tickerText();
  };

  const tickerLoad = (() => {
    var width = $('.ticker-text').width(),
        containerwidth = $('.ticker-container').width(),
        left = containerwidth;
    function tick() {
      if(--left < -width){left = containerwidth;}
      $(".ticker-text").css("margin-left", left + "px");
      setTimeout(tick, 14);
    }
    tick();
  });

  const tickerText = (() =>{
    let texts = "",
        time;
    const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
          d = new Date();
    d.setMinutes(d.getMinutes() - (30));
    
    time = new Intl.DateTimeFormat('default',{
      hour12: true,
      hour: 'numeric',
      minute: 'numeric'
    }).format(d);

    p = monthNames[d.getMonth()] + " " + d.getDate() + " " + time;
    if(Object.keys(notifs).length !== 0){
      Object.entries(notifs).forEach(([key, value]) => {if(key !== "1" && key.startsWith(i18n.language)){texts = texts + value + " " ;}});
      document.getElementById("ticker-text").innerHTML = texts;
    }
    Object.entries(document.querySelectorAll("[id='dateHere']")).forEach(([key, value]) => {value.innerHTML = p;});
  });

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    tickerLoad();
    tickerText();
  },[]);

  return (
    <header className="fixed-top" key="header">
      <div className={notifs[1]==="S"?'banner':'banner warning'}>
        <div className="ticker-container">
          <div className="ticker-text" >
            <p id="ticker-text"></p>
          </div>
        </div>
      </div>
      <div className="container no-padding">
        <nav className="navbar navbar-expand-lg">
          <Link className="navbar-brand" to="/"><img src={logo} alt="Salon Dragon" /></Link>
          <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <Trans><div className="collapse navbar-collapse justify-content-end" id="navbarNav">
            <ul className="navbar-nav">
              <li className="nav-item">
              <Link className="nav-link" to="/#features" title={t('features')}>{t('features')}</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/pricing" title={t('pricing')}>{t('pricing')}</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/faq" title={t('faq')}>{t('faq')}</Link>
              </li>
              {/* <li className="nav-item">
                <Link className="nav-link lead-button inverse" to="#" title={t('signUp')}>{t('signUp')}</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link lead-button" to="#" title={t('signUp')}>{t('logIn')}</Link>
              </li> */}
            </ul>
          </div></Trans>
        </nav>
        <div className="selectLang">
          <p className="" id="langMenu">{t('changeLanguage')}</p>
          <div className="langMenu" aria-labelledby="langMenu">
            <button className="clr" onClick={() => changeLanguage("viet")} title="viet"><img src={viet} alt="viet"/></button>
            <button className="clr" onClick={() => changeLanguage("en")} title="en"><img src={usa} alt="en"/></button>
          </div>
        </div>
        {/* <div className="dropdown selectLang">
          <button className="dropdown-toggle lead-button white" type="button" id="langMenu" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{t('changeLanguage')}</button>
          <div className="dropdown-menu langMenu" aria-labelledby="langMenu">
            <button className="dropdown-item" onClick={() => changeLanguage("viet")} title="viet"><img src={viet} alt="viet"/></button>
            <button className="dropdown-item" onClick={() => changeLanguage("en")} title="en"><img src={usa} alt="en"/></button>
          </div>
          <div className="notifs">
            <button className="dropdown-toggle clr btn" type="button" id="notifsList" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <svg className='newNotif' id="bell" x="0px" y="0px" viewBox="0 0 512 512" style={{"enable-background":"new 0 0 512 512"}}>
                <path d="M256.042,512c-38.435,0-69.592-31.157-69.592-69.592v-34.796c0-13.73,11.125-24.854,24.854-24.854  h89.476c13.73,0,24.854,11.125,24.854,24.854v34.796C325.634,480.843,294.477,512,256.042,512z M236.158,432.466v9.942  c0,10.986,8.898,19.883,19.884,19.883c10.986,0,19.883-8.898,19.883-19.883v-9.942H236.158z"/>
                <path d="M415.706,432.466H96.378c-24.705,0.02-44.758-19.993-44.768-44.708  c0-5.627,1.054-11.204,3.112-16.434l13.223-33.703c12.626-31.903,19.098-65.904,19.088-100.213V169.01  C87.032,75.667,162.699,0,256.042,0s169.01,75.667,169.01,169.01v68.399c-0.04,34.299,6.402,68.3,18.989,100.213l13.223,33.703  c9.057,22.985-2.227,48.963-25.222,58.03C426.841,431.402,421.304,432.456,415.706,432.466z M103.635,382.757h304.814  l-10.638-26.942c-14.883-37.699-22.508-77.874-22.468-118.406V169.01c0-65.884-53.417-119.301-119.301-119.301  S136.741,103.126,136.741,169.01v68.399c0.04,40.532-7.586,80.707-22.468,118.406L103.635,382.757z"/>
              </svg>
            </button>
            <div className="dropdown-menu notifsList" aria-labelledby="notifsList">
              {Object.keys(notifs).map(key => (
                  <div>
                    <p key={key}>{notifs[key]}</p>
                    <div className="dropdown-divider"></div>
                  </div>
              ))}
            </div>
          </div>
        </div> */}
      </div>
    </header>
  );
}

export default header;
