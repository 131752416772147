import "../langs/i18n";

import { Trans, useTranslation } from "react-i18next";

import React from 'react';
import dragon from '../images/salondragon-filler.png';

function tour() {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { t, i18n } = useTranslation();
  let tourImg = [];
  tourImg.push(require("../images/tour/tour.png"));
  tourImg.push(require("../images/tour/tour1.png"));
  tourImg.push(require("../images/tour/tour2.png"));
  tourImg.push(require("../images/tour/tour3.png"));
  return (
    <section className="pricing" id="platforms" key="pricing">
        <div className="container">
        <h2 className="text-center"><Trans>takeATour</Trans></h2>

        <div className="tour-container">
            <div id="tour" className="carousel tour slide" data-ride="carousel">
                <ol className="carousel-indicators">
                {Array.from(Array(tourImg.length), (e, i) => {return(
                        <li data-target="#tour" data-slide-to={i} className={i===0?'active':''}></li>
                );})}
                </ol>
                <div className="carousel-inner">
                {Array.from(Array(tourImg.length), (e, i) => {return(
                    <div className={i===0?'carousel-item active':'carousel-item'}>
                        <img className="lazy loaded" data-src={tourImg[i]} src={tourImg[i]} alt="Apps" data-was-processed="true" />

                        <div className="carousel-caption d-none d-md-block">
                            <h5>Apps</h5>
                            Apps are like projects and hold all <b>your content</b> to be consumed by web apps, mobile apps and other services.
                        </div>
                    </div>
                 );})}
                </div>
                <a className="carousel-control-prev" href="#tour" role="button" data-slide="prev">
                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                </a>
                <a className="carousel-control-next" href="#tour" role="button" data-slide="next">
                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                </a>
            </div>
        </div>

        <div className="dragon-container">
            <img className="dragon-3 lazy loaded" asp-append-version="true" src={dragon} data-was-processed="true" alt=""/>
        </div>
        </div>
    </section>
  );
}

export default tour;
