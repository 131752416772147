import "../langs/i18n";

import { Trans, useTranslation } from "react-i18next";

import React from 'react';

function features() {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { t, i18n } = useTranslation();

    let featuresImg = [];
    featuresImg.push(require("../images/features/checkIn.png"));
    featuresImg.push(require("../images/features/seviceTech.png"));
    featuresImg.push(require("../images/features/tour.png"));
    featuresImg.push(require("../images/features/ipad2.png"));
    featuresImg.push(require("../images/features/eod.png"));
    featuresImg.push(require("../images/features/chairPerson.jpg"));
  return (
    <section className="features" id="features" key="features">
        <div className="container">
            <h2 className="text-center"><Trans>coreFeatures</Trans></h2>
            {/* <div className="text-center subtitle">
               Subtitle <strong>Text</strong>
            </div> */}
            <ul className="row">
            {Array.from(Array(featuresImg.length), (e, i) => {
                return(
                    <li className="col-sm-6 col-md-4 features-item hover-item">
                        <img className="lazy loaded" data-src={featuresImg[i]} alt="Content Versioning" src={featuresImg[i]} />
                        <h5 className="features-item-title"><Trans>{t('coreFeatures'+(i+1))}</Trans></h5>
                        <p className="features-item-text"><Trans>{t('coreFeatures'+(i+1)+'Txt')}</Trans></p>
                    </li>
                );})}
            </ul>
        </div>
    </section>
  );
}

export default features;
