import "../langs/i18n";

import { Trans, useTranslation } from "react-i18next";

import { Link } from "react-router-dom";
import React from 'react';

function footer() {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { t, i18n } = useTranslation();

  return (
    <footer className="footer center-md" key="footer">
      <div className="container">
        <div className="row">
          <div className="col-sm-10">
            <div className="row">
              <div className="fadeIn col-sm-3">
                <ul className="footer-links">
                  <li className="footer-item">
                    <Link
                      className="footer-link"
                      to="/FAQ"
                      title="FAQ"
                      key="faq"
                    >
                      FAQ
                    </Link>
                  </li>
                  <li className="footer-item">
                    <a
                      className="footer-link"
                      href="javascript:void(0)"
                      target="_blank"
                      rel="noopener"
                      title="Documentation"
                    >
                      Documentation
                    </a>
                  </li>
                  <li className="footer-item">
                    <Link
                      className="footer-link"
                      to="/privacy"
                      title="Privacy"
                      key="privacy"
                    >
                      Privacy
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="fadeIn col-sm-3">
                <ul className="footer-links">
                  <li className="footer-item">
                    <a
                      className="footer-link"
                      href="javascript:void(0)"
                      title="Salon-Dragon vs Competitors"
                    >
                      Compare Features
                    </a>
                  </li> 

                  <li className="footer-item">
                    
                  <Link
                      className="footer-link"
                      to="/Pricing"
                      title="Pricing"
                      key="pricing"
                    >
                      Pricing
                    </Link>
                  </li>
                  <li className="footer-item">
                    <a
                      className="footer-link"
                      href="javascript:void(0)"
                      target="_blank"
                      rel="noopener"
                      title="Support"
                    >
                      Support
                    </a>
                  </li>
                </ul>
              </div>
              {/* <div className="fadeIn col-sm-3">
                <ul className="footer-links">
                  <li className="footer-item">
                    <a
                      className="footer-link"
                      href="javascript:void(0)"
                      title="Privacy Policy"
                    >
                      Privacy
                    </a>
                  </li>
                  <li className="footer-item">
                    <a
                      className="footer-link"
                      href="javascript:void(0)"
                      title="Legal"
                    >
                      Legal
                    </a>
                  </li>
                  <li className="footer-item">
                    <a
                      className="footer-link"
                      href="javascript:void(0)"
                      title="Sitemap"
                    >
                      Sitemap
                    </a>
                  </li>
                </ul>
              </div> */}
              <div className="col-sm-3">
                <ul className="footer-links">
                  <li className="footer-item">
                    <a
                      className="footer-link"
                      href="javascript:void(0)"
                      target="_blank"
                      rel="noopener"
                      title="LinkedIn"
                    >
                      LinkedIn
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-sm-2">
            <img src={require("../images/salonDragon.jpg")} alt="Salon Dragon" />
          </div>
        </div>
      </div>
    </footer>
  );
}

export default footer;
