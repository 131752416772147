import React from 'react';
import './css/App.min.css';
import Content from "./sections/content";

function App() {
  return (
    <div className="outer_container">
      <Content />
    </div>
  );
}

export default App;
