import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import vietLang from './viet.json';
import enLang from './en.json';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    // we init with resources
    resources: {
      en: {translations: enLang},
      viet: {translations: vietLang}
    },
    fallbackLng: "en",
    debug: false,

    // have a common namespace used around the full app
    ns: ["translations"],
    defaultNS: "translations",

    keySeparator: false, // char to split namespace from key

    interpolation: {escapeValue: false} // integration dynamic values into your translations | i18next.t('key', { author });
  });

export default i18n;