import React, { useEffect } from 'react';
import "../langs/i18n";
import { useTranslation, Trans } from "react-i18next";

import dragon from '../images/salondragon-filler-1.png';

function faq() {

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { t, i18n } = useTranslation();

  document.title = "Salon Dragon | FAQs";

  const accordianRun = () => {
    var acc = document.getElementsByClassName("accordion"),
        i;
    for (i = 0; i < acc.length; i++) {
      acc[i].addEventListener("click", function() {
        this.classList.toggle("active");
        var panel = this.nextElementSibling;
        if (panel.classList.contains("active")) {
          panel.classList.remove("active");
          panel.style.height = "0px";
        } else {
          panel.classList.add("active");
          panel.style.height = panel.children[0].offsetHeight + 'px';
        }
      });
    }
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    accordianRun();
  },[]);

  return (
    <section className="faq" id="faq" key="faq">
      <div className="container">
        <Trans>
          <h2 className="text-center">{t('frequentlyAskedQuestions')}</h2>
          <div className="row">
            <div className="text col-sm-6">
              <button className="accordion">{t("faqQ1")}</button>
              <div className="panel"><p>{t("faqA1")}</p></div>

              <button className="accordion">{t("faqQ2")}</button>
              <div className="panel"><p>{t("faqA2")}</p></div>

              <button className="accordion">{t("faqQ3")}</button>
              <div className="panel"><p>{t("faqA3")}</p></div>

              <button className="accordion">{t("faqQ4")}</button>
              <div className="panel"><p>{t("faqA4")}</p></div>
            </div>
            <div className="text col-sm-6">
              <button className="accordion">{t("faqQ4")}</button>
              <div className="panel"><p>{t("faqA4")}</p></div>

              <button className="accordion">{t("faqQ1")}</button>
              <div className="panel"><p>{t("faqA1")}</p></div>

              <button className="accordion">{t("faqQ2")}</button>
              <div className="panel"><p>{t("faqA2")}</p></div>

              <button className="accordion">{t("faqQ3")}</button>
              <div className="panel"><p>{t("faqA3")}</p></div>
            </div>
          </div>
        </Trans>
      </div>
      <div className="container dragon-container">
        <img className="dragon-4" src={dragon} alt=""/>
      </div>
    </section>
  );
}

export default faq;