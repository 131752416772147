import "jquery/dist/jquery.min.js";
import "bootstrap/dist/js/bootstrap.bundle.js";
import "bootstrap/dist/css/bootstrap.css";
import './index.css';

import * as serviceWorker from './serviceWorker';

import { Route, BrowserRouter as Router, Switch } from "react-router-dom";

import App from './App';
import FAQ from "./sections/faq";
import Footer from "./common/footer";
import Header from "./common/header";
import Pricing from './sections/pricing';
import Privacy from './sections/privacy';
import React from 'react';
import ReactDOM from 'react-dom';

function onLoad() {
  window.addEventListener('load', function(){var ht = document.getElementsByTagName('header')[0].offsetHeight;
  document.getElementsByTagName('body')[0].style.paddingTop=ht+"px";});
}

ReactDOM.render(
  <React.StrictMode>
    {onLoad()}
    <Router>
      <div>
        <Header key="header"/>
        <Switch key="switch">
          <Route exact path="/" key={document.location.href.split('/').pop()}><App /></Route>
          <Route path="/pricing" key={document.location.href.split('/').pop()}><Pricing /></Route>
          <Route path="/FAQ" key={document.location.href.split('/').pop()}><FAQ /></Route>
          <Route path="/privacy" key={document.location.href.split('/').pop()}><Privacy /></Route>
        </Switch>
        <Footer key="Footer" />
      </div>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
