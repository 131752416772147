import "../langs/i18n";

import React, { useEffect } from 'react';
import { Trans, useTranslation } from "react-i18next";

import $ from 'jquery';

function testimonials() {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { t, i18n } = useTranslation();

  let testimonialsImg = [];
  testimonialsImg.push(require("../images/testimonials/customer1.jpeg"));
  testimonialsImg.push(require("../images/testimonials/customer2.jpeg"));
  testimonialsImg.push(require("../images/testimonials/customer3.jpeg"));
  testimonialsImg.push(require("../images/testimonials/customer4.jpeg"));

  var currentIndex = 0;

  const loadTestimonial = () => {
    var currentIndex = 0;
    $(".testimonial-user").click(function () {
      var tag = $(this).data("target");
      goTo(parseInt(tag, 10));
    });
    $(".testimonial .carousel-control-prev").click(function () {
      var size = $(".testimonial-item").length;
      if (currentIndex === 0) { goTo(size - 1); }
      else { goTo(currentIndex - 1); }
    });
    $(".testimonial .carousel-control-next").click(function () {
      var size = $(".testimonial-item").length;
      if (currentIndex === size - 1) { goTo(0); }
      else { goTo(currentIndex + 1); }
    });
    function goTo(index) {
      $(".testimonial-user").removeClass("active");
      $(".testimonial-item").each(function (_, item) {
        var current = $(item).data("target");
        if (current === index) { $(item).css("display", "flex").hide().fadeIn(); }
        else { $(item).fadeOut(); }
      });
      $(".testimonial-user").each(function (_, item) {
        var current = $(item).data("target");
        if (current === index) { $(item).addClass("active"); }
        else { $(item).removeClass("active"); }
      });
      currentIndex = index;
    }
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
  }, []);

  return (
    <section className="testimonial" id="testimonials" key="testimonials">
      <div className="container">
        <div className="testimonial-inner">
          <h2><Trans>testimonialHead</Trans></h2>

          <div id="testimonialSlider" className="carousel testimonial-inner slide" data-ride="carousel">
            <div className="carousel-inner testimonial-items">
              {Array.from(Array(testimonialsImg.length), (e, i) => {
                return (
                  <div className={i === 0 ? 'carousel-item active' : 'carousel-item'} data-target={i} key={i}>
                    <div className='testimonial-item row'>
                      <div className="col-4">
                        <div className="testimonial-image">
                          <img className="" data-src={testimonialsImg[i]} src={testimonialsImg[i]} alt="Testimonial" />
                          {/* <div className="testimonial-image-logo">
                          <div className="testimonial-logo">
                            <a target="blank" href="#testimonial">
                              <img className="lazy loaded" data-src={testimonial1} alt="Logo" src={testimonial1} data-was-processed="true" />
                            </a>
                          </div>
                        </div> */}
                        </div>
                      </div>
                      <div className="col-8">
                        <i className="icon-quote-left"></i>
                        <svg version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 512 512" style={{ "enable-background": "new 0 0 512 512" }}><g>
                          <path d="M224.001,74.328c5.891,0,10.667-4.776,10.667-10.667s-4.776-10.667-10.667-10.667   c-123.653,0.141-223.859,100.347-224,224v64c-0.185,64.99,52.349,117.825,117.338,118.01   c64.99,0.185,117.825-52.349,118.01-117.338c0.185-64.99-52.349-117.825-117.338-118.01c-38.374-0.109-74.392,18.499-96.506,49.861   C23.48,163.049,113.514,74.485,224.001,74.328z" />
                          <path d="M394.667,223.662c-38.154,0.03-73.905,18.63-95.829,49.856   c1.976-110.469,92.01-199.033,202.496-199.189c5.891,0,10.667-4.776,10.667-10.667s-4.776-10.667-10.667-10.667   c-123.653,0.141-223.859,100.347-224,224v64c0,64.801,52.532,117.333,117.333,117.333S512,405.796,512,340.995   S459.469,223.662,394.667,223.662z" />
                        </g></svg>

                        <div className="testimonial-text">
                          <Trans>{t('testimonial' + (i + 1))}</Trans>
                        </div>
                        <div className="testimonial-company">
                          <span className="testimonial-company-line"></span>
                          <Trans>{t('testimonial' + (i + 1) + 'Name')}</Trans>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="carousel-indicators testimonial-users">
              {Array.from(Array(testimonialsImg.length), (e, i) => {
                return (
                  <div data-target="#testimonialSlider" className={i === 0 ? 'testimonial-user active' : 'testimonial-user'} key={i} data-slide-to={i}>
                    <img className="lazy loaded" data-src={testimonialsImg[i]} alt="Person" src={testimonialsImg[i]} data-was-processed="true" />
                  </div>
                );
              })}
            </div>
            <a className="carousel-control-prev" href="#testimonialSlider" role="button" data-slide="prev">
              <span className="carousel-control-prev-icon" aria-hidden="true"></span>
            </a>
            <a className="carousel-control-next" href="#testimonialSlider" role="button" data-slide="next">
              <span className="carousel-control-next-icon" aria-hidden="true"></span>
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}

export default testimonials;
