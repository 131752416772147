import "../langs/i18n";

import React from 'react';
import dragon from '../images/salondragon-filler-1.png';

let tourImg = [];
tourImg.push(require("../images/tour/tour.png"));
tourImg.push(require("../images/tour/tour.png"));

function pricing() {
    document.title = "Salon Dragon | Pricing";
  return (
    <section className="pricing pricingPage" id="platforms" key="pricing">
      <section class="text-banner sm">
        <div class="container">
          <h1>Pricing and Plans</h1>

          <p>All about usage and support options</p>
        </div>
      </section>
      <div className="container">
        <div data-target="hosted" className="pricing-alternative" >
          <div className="row pricing-section">
            <div className="col-lg-11 offset-lg-1 pricing-options"> 
              <ul className="row">
                <li className="pricing-option text-center col-lg-12">
                <div className="pricing-option-price">Cloud Salon Dragon<span>$9 per technician (upto $99/month)</span></div>
                <div className="row">
                  <div className="col-sm-4">
                    <div className="pricing-option-description">Full white-glove salon setup</div>
                    <ul className="pricing-option-facts">
                      <li className="min">service menu</li>
                      <li className="min">service pricing</li>
                      <li className="min">worker list</li>
                      <li className="min">staff scheduling</li>
                      <li className="min">user permissions</li>
                      <li className="min">import your customer profiles</li>
                    </ul>
                  </div>
                  <div className="col-sm-4">
                    <div className="pricing-option-description">Voice call training and support (30 min max)</div>
                    <ul className="pricing-option-facts">
                      <li className="min">schedule 30 min training/support session</li>
                      <li className="min">we walk you through using the software</li>
                      <li className="min">we answer all your questions</li>
                      <li className="min">we put you in a position to be successful</li>
                    </ul>
                  </div>
                  <div className="col-sm-4">
                    <div className="pricing-option-description">Full access to all appointment schedule table</div>
                    <ul className="pricing-option-facts">
                      <li className="min">Full speed ahead</li>
                      <li className="min">Create, move, reschedule new appointments</li>
                      <li className="min">Assign, adjust, and close running appointments</li>
                      <li className="min">Review every customer’s profile and service history</li>
                    </ul>
                  </div>
                </div>
                </li>
              </ul>
            </div>
            <div className="addIcon col-sm-12"><svg height="448pt" viewBox="0 0 448 448" width="448pt" xmlns="http://www.w3.org/2000/svg"><path d="m408 184h-136c-4.417969 0-8-3.582031-8-8v-136c0-22.089844-17.910156-40-40-40s-40 17.910156-40 40v136c0 4.417969-3.582031 8-8 8h-136c-22.089844 0-40 17.910156-40 40s17.910156 40 40 40h136c4.417969 0 8 3.582031 8 8v136c0 22.089844 17.910156 40 40 40s40-17.910156 40-40v-136c0-4.417969 3.582031-8 8-8h136c22.089844 0 40-17.910156 40-40s-17.910156-40-40-40zm0 0"/></svg></div>
            <div className="extraPack-container">
                <div className="extraPacks">
                  <h2 className="text-center">Our add-on Packages</h2>
                  <div className="row">
                    <div className="col-sm-4">
                      <div className="packCont">
                        <div className="pricing-option-price">Ipad Check-In with Ticket Printing Package<span>+$19 per month</span></div>
                        <div className="pricing-option-description">Advanced and integrated kiosk check-in</div>
                        <ul className="pricing-option-facts">
                          <li className="min">customers self-check-in on ipad</li>
                          <li className="min">customer and service shows-up on schedule table</li>
                          <li className="min">just drag-n-drop to assign customer to technician</li>
                          <li className="min">small thermal printer automatically pre-prints ticket</li>
                          <li className="min">includes customer name, service, technician, pricing</li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-sm-4">
                      <div className="packCont">
                        <div className="pricing-option-price">Payroll Management Package<span>+$49 per month</span></div>
                        <div className="pricing-option-description">Fully integrated payroll with custom settings</div>
                        <ul className="pricing-option-facts">
                          <li className="min">set individual worker commission rates</li>
                          <li className="min">set individual worker check/cash rates</li>
                          <li className="min">set individual cleaning/misc charges</li>
                          <li className="min">pre-printed (bi)weekly itemized worker sheets</li>
                          <li className="min">pre-printed checks with exact worker amounts</li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-sm-4">
                      <div className="packCont">
                        <div className="pricing-option-price">Ipad Check-In with Ticket Printing Package<span>+$19 per month</span></div>
                        <div className="pricing-option-description">Full access to all appointment schedule table</div>
                        <ul className="pricing-option-facts">
                          <li className="min">Full speed ahead</li>
                          <li className="min">Create, move, reschedule new appointments</li>
                          <li className="min">Assign, adjust, and close running appointments</li>
                          <li className="min">Review every customer’s profile and service history</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  
                  <a
                    className="pricing-option-button lead-button xl"
                    href="/"
                    rel="noopener"
                    title="Yaay!"
                  >
                    Contact Sales
                  </a>
                </div>
              </div>
          </div>
        </div>

        <div className="dragon-container">
          <img
            className="dragon-3 lazy loaded"
            asp-append-version="true"
            src={dragon}
            data-was-processed="true"
            alt=""
          />
        </div>
      </div>
    </section>
  );
}

export default pricing;
